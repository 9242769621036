export enum ProductCategory {
  semiAutomat = "semi-automat",
  automat = "automat",
  awd = "awd",
}

export enum TechnicalSpecificationKey {
  cutting_range = "cutting_range",
  technical_data = "technical_data",
  optional_accessories = "optional_accessories",
  roller_tables = "roller_tables",
}

export enum TechnicalSpecificationLabelKey {
  // Cutting range
  "90deg" = "90deg",
  "45deg" = "45deg",
  "60deg" = "60deg",
  // Technical data
  technical_data_item_1 = "technical_data_item_1",
  technical_data_item_2 = "technical_data_item_2",
  technical_data_item_3 = "technical_data_item_3",
  technical_data_item_4 = "technical_data_item_4",
  technical_data_item_5 = "technical_data_item_5",
  technical_data_item_6 = "technical_data_item_6",
  technical_data_item_7 = "technical_data_item_7",
  technical_data_item_8 = "technical_data_item_8",
  technical_data_item_9 = "technical_data_item_9",
  technical_data_item_10 = "technical_data_item_10",
  technical_data_item_11 = "technical_data_item_11",
  technical_data_item_12 = "technical_data_item_12",
  technical_data_item_13 = "technical_data_item_13",
  technical_data_item_14 = "technical_data_item_14",
  technical_data_item_15 = "technical_data_item_15",
  // Optional accessories
  optional_accessories_item_1 = "optional_accessories_item_1",
  optional_accessories_item_2 = "optional_accessories_item_2",
  optional_accessories_item_3 = "optional_accessories_item_3",
  optional_accessories_item_4 = "optional_accessories_item_4",
  optional_accessories_item_5 = "optional_accessories_item_5",
  optional_accessories_item_6 = "optional_accessories_item_6",
  optional_accessories_item_7 = "optional_accessories_item_7",
  optional_accessories_item_8 = "optional_accessories_item_8",
  optional_accessories_item_9 = "optional_accessories_item_9",
  optional_accessories_item_10 = "optional_accessories_item_10",
  optional_accessories_item_11 = "optional_accessories_item_11",
  optional_accessories_item_12 = "optional_accessories_item_12",
  optional_accessories_item_14 = "optional_accessories_item_14",
  optional_accessories_item_13 = "optional_accessories_item_13",
  optional_accessories_item_15 = "optional_accessories_item_15",
  optional_accessories_item_16 = "optional_accessories_item_16",
  optional_accessories_item_17 = "optional_accessories_item_17",
  optional_accessories_item_18 = "optional_accessories_item_18",
  optional_accessories_item_19 = "optional_accessories_item_19",
  // Roller conveyors
  RB_500 = "RB_500",
  RB_700 = "RB_700",
  RB_1000 = "RB_1000",
  RBM_500 = "RBM_500",
  RBM_700 = "RBM_700",
  RBM_1000 = "RBM_1000",
  RN_500 = "RN_500",
  RN_700 = "RN_700",
  L0_A = "L0_A",
  L1_A = "L1_A",
  L2_A = "L2_A",
  L0_B = "L0_B",
  L1_B = "L1_B",
  L2_B = "L2_B",
  BV_500 = "BV_500",
  BV_700 = "BV_700",
  BV_1000 = "BV_1000",
}
