import clsx from "clsx";
import { Children, Fragment, ReactNode } from "react";

import { title } from "../primitives";
import AppLink from "../app-link";

import { IconArrowRight } from "@/icons/svgr";

interface SectionProps {
  className?: string;
  innerClassName?: string;
  children: ReactNode;
  id?: string;
  width?: "none" | "sm" | "md" | "lg" | "xl" | "2xl";
  padding?: "none" | "xs" | "sm" | "md" | "lg" | "xl";
}

export const Section = ({
  className,
  innerClassName,
  children,
  id,
  width = "lg",
  padding = "lg",
}: SectionProps) => {
  const childrenAsArray = Children.toArray(children);

  return (
    <section
      className={clsx(
        "container flex flex-col items-center text-center",
        //"w-full flex flex-col items-center text-center",
        {
          "max-w-none": width === "none",
          "w-full": width !== "none",
          "max-w-912": width === "sm",
          "max-w-976": width === "md",
          "max-w-1136": width === "lg",
          "max-w-1360": width === "xl",
          "max-w-1464": width === "2xl",
          "py-0": padding === "none",
          "py-section-xs": padding === "xs",
          "py-section-xs lg:py-section-small": padding === "sm",
          "py-section-small lg:py-section-medium": padding === "md",
          "py-section-medium lg:py-section": padding === "lg",
          "py-section lg:py-section-large": padding === "xl",
        },
        className,
      )}
      id={id}
    >
      {childrenAsArray.map((child, index) => {
        return (
          <div
            key={index}
            className={clsx("w-full flex justify-center", innerClassName)}
          >
            {child}
          </div>
        );
      })}
    </section>
  );
};

interface WrapperProps {
  className?: string;
  children: ReactNode;
  width?: "none" | "sm" | "md" | "lg" | "xl";
  padding?: "none" | "xs" | "sm" | "md" | "lg" | "xl";
}

export const Wrapper = ({
  className,
  children,
  width = "lg",
  padding = "lg",
}: WrapperProps) => {
  const childrenAsArray = Children.toArray(children);

  return (
    <div
      className={clsx(
        "w-full flex flex-col gap-section-medium lg:gap-section text-left lg:text-center",
        {
          "max-w-864": width === "sm",
          "max-w-976": width === "md",
          "max-w-1088": width === "lg",
          "max-w-1360": width === "xl",
          "py-section-xs": padding === "xs",
          "py-2xl lg:py-section-small": padding === "sm",
          "py-section-medium lg:py-section-medium": padding === "md",
          "py-section-xs lg:py-section": padding === "lg",
          "py-section lg:py-section-large": padding === "xl",
        },
        className,
      )}
    >
      {childrenAsArray.map((child, index) => {
        return <Fragment key={index}>{child}</Fragment>;
      })}
    </div>
  );
};

interface ImagesProps {
  className?: string;
  children: ReactNode;
}

export const Images = ({ className, children }: ImagesProps) => {
  const childrenAsArray = Children.toArray(children);

  return (
    <div className="grid grid-cols-2 gap-xl">
      {childrenAsArray.map((child, index) => {
        return (
          <div
            key={index}
            className="group col-span-2 md:col-span-1 flex justify-center"
          >
            {child}
          </div>
        );
      })}
    </div>
  );
};

interface SectionTextProps {
  className?: string;
  children: ReactNode;
  heading: ReactNode;
  subHeading?: ReactNode;
  link?: string;
  linkLabel?: ReactNode;
  childrenFullWidth?: ReactNode;
  linkPosition?: "inside" | "outside";
  extendedGrid?: boolean;
}

export const SectionText = ({
  className,
  children,
  heading,
  subHeading,
  link,
  linkLabel,
  childrenFullWidth,
  linkPosition = "inside",
  extendedGrid = false,
}: SectionTextProps) => {
  return (
    <div
      className={clsx(
        "flex flex-col gap-lg text-left justify-between",
        "lg:grid lg:grid-cols-12 xl:grid-cols-10 gap-xl lg:gap-section",
        className,
      )}
    >
      <h2
        className={clsx(
          "group",
          title({ size: "h3", uppercase: true }),
          extendedGrid
            ? "lg:col-span-4 xl:col-span-3"
            : "lg:col-span-5 xl:col-span-4",
        )}
      >
        {heading}
      </h2>

      <div
        className={clsx(
          "group flex flex-col items-start gap-2xl",
          extendedGrid
            ? "lg:col-span-8 xl:col-span-7"
            : "lg:col-span-7 xl:col-span-6",
        )}
      >
        <div className="flex flex-col items-start gap-xl lg:gap-2xl">
          <div className="max-w-full">
            {subHeading && (
              <h3
                className={clsx(
                  title({
                    size: "h3",
                    uppercase: true,
                    color: "gray-light",
                  }),
                  "mb-2 lg:mb-4",
                )}
              >
                {subHeading}
              </h3>
            )}
            {children}
          </div>
          {link && linkLabel && linkPosition === "inside" && (
            /* @ts-ignore */
            <AppLink
              Icon={<IconArrowRight />}
              className="lg:mt-xl_lg:mt-2xl_"
              href={link}
            >
              {linkLabel}
            </AppLink>
          )}
        </div>
      </div>

      {childrenFullWidth && (
        <div className="group lg:col-span-12 xl:col-span-10 -mx-md md:mx-0">
          {childrenFullWidth}
        </div>
      )}

      {link && linkLabel && linkPosition === "outside" && (
        <div className="group lg:col-span-7 lg:col-start-6  xl:col-span-6 xl:col-start-5 flex flex-col items-start gap-2xl">
          {/* @ts-ignore */}
          <AppLink
            Icon={<IconArrowRight />}
            className="lg:mt-xl_lg:mt-2xl_"
            href={link}
          >
            {linkLabel}
          </AppLink>
        </div>
      )}
    </div>
  );
};
