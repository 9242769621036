"use client";

import React, { ReactNode, createContext, useState } from "react";

interface ProductContextProps {
  hoveredCuttingRange:
    | string
    | null /*TechnicalSpecificationLabelKey["90deg"] | TechnicalSpecificationLabelKey["45deg"] | TechnicalSpecificationLabelKey["60deg"]*/;
  handleCuttingRange: (item: string | null) => () => void;
}

const defaultProps: ProductContextProps = {
  hoveredCuttingRange: null,
  handleCuttingRange: () => () => {},
};

export const ProductContext = createContext<ProductContextProps>(defaultProps);

interface Props {
  children: ReactNode;
}

export const ProductProvider: React.FC<Props> = ({ children }) => {
  const [hoveredCuttingRange, setHoveredCuttingRange] = useState<string | null>(
    null,
  );

  const handleCuttingRange = (hoveredCuttingRange: string | null) => () => {
    setHoveredCuttingRange(hoveredCuttingRange);
  };

  return (
    <ProductContext.Provider
      value={{ hoveredCuttingRange, handleCuttingRange }}
    >
      {children}
    </ProductContext.Provider>
  );
};
