import { ReactNode } from "react";
import clsx from "clsx";
import { Link } from "@nextui-org/react";

import { bodyText } from "@/components/primitives";

interface Props {
  className?: string;
  children: string;
  href: string;
  Icon: ReactNode;
}

export default function AppLink({
  className,
  children,
  href = "#",
  Icon,
}: Props) {
  return (
    <Link
      className={clsx(
        bodyText({ size: "xl" }),
        "group",
        "min-w-[230px]",
        "h-14",
        "text-left",
        //"lg:gap-2",
        "text-black",
        "lg:py-sm",
        "transition-all",
        "duration-500",
        "overflow-hidden",
        className,
      )}
      href={href}
      underline="none"
    >
      <span className="-ml-[32px] pr-[8px] group-hover:ml-0 transition-all group-hover:delay-300 duration-300">
        {Icon}
      </span>
      <span className="flex-1 pr-[8px]">{children}</span>
      <span className="group-hover:mr-[-32px] transition-all duration-300">
        {Icon}
      </span>
      <span
        className={clsx(
          "absolute right-0 bottom-0 w-[calc(200%+96px)] flex gap-[96px]",
          "before:w-full before:h-[1px] before:bg-black",
          "after:w-full after:h-[1px] after:bg-black",
          "group-hover:translate-x-[calc(50%+48px)] transition-all duration-300",
        )}
      />
    </Link>
  );
}
