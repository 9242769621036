import { Product as ProductInterface } from "../app/api/models/types";

export const getProductCategory = (product: ProductInterface) =>
  product.category;

export const getProductId = (product: ProductInterface) =>
  product.name.replaceAll(" ", "-").replaceAll(".", "-");

export const getProductImageFolder = (product: ProductInterface) =>
  `/images/models/${getProductId(product)}`;

export const getProductLink = (product: ProductInterface, locale: string) =>
  `/${locale}/models/${getProductId(product)}`;
