import {
  TechnicalSpecificationKey,
  TechnicalSpecificationLabelKey,
} from "../../app/api/models/enums";

export const TECHNICAL_SPECIFICATION_HEADINGS = {
  [TechnicalSpecificationKey.cutting_range]: "cutting-range",
  [TechnicalSpecificationKey.technical_data]: "technical-data",
  [TechnicalSpecificationKey.optional_accessories]: "optional-accessories",
  [TechnicalSpecificationKey.roller_tables]: "roller-tables",
};

export const TECHNICAL_SPECIFICATION_LABELS = {
  // Cutting range
  [TechnicalSpecificationLabelKey["90deg"]]:
    "technical-specification-labels.90-deg",
  [TechnicalSpecificationLabelKey["45deg"]]:
    "technical-specification-labels.45-deg-right",
  [TechnicalSpecificationLabelKey["60deg"]]:
    "technical-specification-labels.60-deg-right",
  // Technical data
  [TechnicalSpecificationLabelKey.technical_data_item_1]:
    "technical-specification-labels.technical_data_item_1",
  [TechnicalSpecificationLabelKey.technical_data_item_2]:
    "technical-specification-labels.technical_data_item_2",
  [TechnicalSpecificationLabelKey.technical_data_item_3]:
    "technical-specification-labels.technical_data_item_3",
  [TechnicalSpecificationLabelKey.technical_data_item_4]:
    "technical-specification-labels.technical_data_item_4",
  [TechnicalSpecificationLabelKey.technical_data_item_5]:
    "technical-specification-labels.technical_data_item_5",
  [TechnicalSpecificationLabelKey.technical_data_item_6]:
    "technical-specification-labels.technical_data_item_6",
  [TechnicalSpecificationLabelKey.technical_data_item_7]:
    "technical-specification-labels.technical_data_item_7",
  [TechnicalSpecificationLabelKey.technical_data_item_8]:
    "technical-specification-labels.technical_data_item_8",
  [TechnicalSpecificationLabelKey.technical_data_item_9]:
    "technical-specification-labels.technical_data_item_9",
  [TechnicalSpecificationLabelKey.technical_data_item_10]:
    "technical-specification-labels.technical_data_item_10",
  [TechnicalSpecificationLabelKey.technical_data_item_11]:
    "technical-specification-labels.technical_data_item_11",
  [TechnicalSpecificationLabelKey.technical_data_item_12]:
    "technical-specification-labels.technical_data_item_12",
  [TechnicalSpecificationLabelKey.technical_data_item_13]:
    "technical-specification-labels.technical_data_item_13",
  [TechnicalSpecificationLabelKey.technical_data_item_14]:
    "technical-specification-labels.technical_data_item_14",
  [TechnicalSpecificationLabelKey.technical_data_item_15]:
    "technical-specification-labels.technical_data_item_15",
  // Optional accessories
  [TechnicalSpecificationLabelKey.optional_accessories_item_1]:
    "technical-specification-labels.optional_accessories_item_1",
  [TechnicalSpecificationLabelKey.optional_accessories_item_2]:
    "technical-specification-labels.optional_accessories_item_2",
  [TechnicalSpecificationLabelKey.optional_accessories_item_3]:
    "technical-specification-labels.optional_accessories_item_3",
  [TechnicalSpecificationLabelKey.optional_accessories_item_4]:
    "technical-specification-labels.optional_accessories_item_4",
  [TechnicalSpecificationLabelKey.optional_accessories_item_5]:
    "technical-specification-labels.optional_accessories_item_5",
  [TechnicalSpecificationLabelKey.optional_accessories_item_6]:
    "technical-specification-labels.optional_accessories_item_6",
  [TechnicalSpecificationLabelKey.optional_accessories_item_7]:
    "technical-specification-labels.optional_accessories_item_7",
  [TechnicalSpecificationLabelKey.optional_accessories_item_8]:
    "technical-specification-labels.optional_accessories_item_8",
  [TechnicalSpecificationLabelKey.optional_accessories_item_9]:
    "technical-specification-labels.optional_accessories_item_9",
  [TechnicalSpecificationLabelKey.optional_accessories_item_10]:
    "technical-specification-labels.optional_accessories_item_10",
  [TechnicalSpecificationLabelKey.optional_accessories_item_11]:
    "technical-specification-labels.optional_accessories_item_11",
  [TechnicalSpecificationLabelKey.optional_accessories_item_12]:
    "technical-specification-labels.optional_accessories_item_12",
  [TechnicalSpecificationLabelKey.optional_accessories_item_13]:
    "technical-specification-labels.optional_accessories_item_13",
  [TechnicalSpecificationLabelKey.optional_accessories_item_14]:
    "technical-specification-labels.optional_accessories_item_14",
  [TechnicalSpecificationLabelKey.optional_accessories_item_15]:
    "technical-specification-labels.optional_accessories_item_15",
  [TechnicalSpecificationLabelKey.optional_accessories_item_16]:
    "technical-specification-labels.optional_accessories_item_16",
  [TechnicalSpecificationLabelKey.optional_accessories_item_17]:
    "technical-specification-labels.optional_accessories_item_17",
  [TechnicalSpecificationLabelKey.optional_accessories_item_18]:
    "technical-specification-labels.optional_accessories_item_18",
  [TechnicalSpecificationLabelKey.optional_accessories_item_19]:
    "technical-specification-labels.optional_accessories_item_19",
  // Roller conveyors
  [TechnicalSpecificationLabelKey.RB_500]:
    "technical-specification-labels.RB_500",
  [TechnicalSpecificationLabelKey.RB_700]:
    "technical-specification-labels.RB_700",
  [TechnicalSpecificationLabelKey.RB_1000]:
    "technical-specification-labels.RB_1000",
  [TechnicalSpecificationLabelKey.RBM_500]:
    "technical-specification-labels.RBM_500",
  [TechnicalSpecificationLabelKey.RBM_700]:
    "technical-specification-labels.RBM_700",
  [TechnicalSpecificationLabelKey.RBM_1000]:
    "technical-specification-labels.RBM_1000",
  [TechnicalSpecificationLabelKey.RN_500]:
    "technical-specification-labels.RN_500",
  [TechnicalSpecificationLabelKey.RN_700]:
    "technical-specification-labels.RN_700",
  [TechnicalSpecificationLabelKey.L0_A]: "technical-specification-labels.L0_A",
  [TechnicalSpecificationLabelKey.L1_A]: "technical-specification-labels.L1_A",
  [TechnicalSpecificationLabelKey.L2_A]: "technical-specification-labels.L2_A",
  [TechnicalSpecificationLabelKey.L0_B]: "technical-specification-labels.L0_B",
  [TechnicalSpecificationLabelKey.L1_B]: "technical-specification-labels.L1_B",
  [TechnicalSpecificationLabelKey.L2_B]: "technical-specification-labels.L2_B",
  [TechnicalSpecificationLabelKey.BV_500]:
    "technical-specification-labels.BV_500",
  [TechnicalSpecificationLabelKey.BV_700]:
    "technical-specification-labels.BV_700",
  [TechnicalSpecificationLabelKey.BV_1000]:
    "technical-specification-labels.BV_1000",
};

export const TECHNICAL_SPECIFICATION_LABELS_SHORT = {
  // Cutting range
  [TechnicalSpecificationLabelKey["90deg"]]:
    "technical-specification-labels-short.90-deg",
  [TechnicalSpecificationLabelKey["45deg"]]:
    "technical-specification-labels-short.45-deg",
  [TechnicalSpecificationLabelKey["60deg"]]:
    "technical-specification-labels-short.60-deg",
  [TechnicalSpecificationLabelKey.technical_data_item_1]:
    "technical-specification-labels-short.technical_data_item_1",
  [TechnicalSpecificationLabelKey.technical_data_item_2]:
    "technical-specification-labels-short.technical_data_item_2",
  [TechnicalSpecificationLabelKey.technical_data_item_3]:
    "technical-specification-labels-short.technical_data_item_3",
  [TechnicalSpecificationLabelKey.technical_data_item_9]:
    "technical-specification-labels-short.technical_data_item_9",
  [TechnicalSpecificationLabelKey.technical_data_item_14]:
    "technical-specification-labels-short.technical_data_item_14",
  [TechnicalSpecificationLabelKey.technical_data_item_15]:
    "technical-specification-labels-short.technical_data_item_15",
};
