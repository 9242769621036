"use client";

import { Chip } from "@nextui-org/react";
import clsx from "clsx";
import { useTranslations } from "next-intl";

import { Product as ProductInterface } from "../../../app/api/models/types";

import { CATEGORIES_LABELS } from "@/consts/product/catagories";
import ErrorBoundary from "@/components/error-boundary";

interface Props {
  className?: string;
  product: ProductInterface;
  size?: "sm" | "md" | "lg";
}

export default function ProductTags({
  className,
  product,
  size = "md",
}: Props) {
  const t = useTranslations("product");
  const { category, tags = [] } = product;

  return (
    <ErrorBoundary>
      <div className={clsx(className, "flex flex-wrap gap-tags")}>
        <Chip size={size}>{t(CATEGORIES_LABELS[category])}</Chip>

        {tags.map((tag) => (
          <Chip key={tag} color="secondary" size={size}>
            {t(`tag.${tag}`)}
          </Chip>
        ))}
      </div>
    </ErrorBoundary>
  );
}
