import clsx from "clsx";

import {
  IconCurProfileVertical,
  IconCutProfile,
  IconCutProfileCircle,
  IconCutProfileHorizontal,
} from "@/icons/svgr";

interface Props {
  className?: string;
  values?: string[];
  compact?: boolean;
}

export default function ProductCutsRange({
  className,
  values,
  compact,
}: Props) {
  if (!Array.isArray(values)) {
    return null;
  }

  const firstValue = values[0];
  const secondValue = values[1];
  const secondValueParsed = secondValue
    .replaceAll("mm", "")
    .replaceAll(" ", "")
    .split("×");

  return (
    <span
      className={clsx(
        className,
        compact && "flex-col",
        "inline-flex gap-sm xl:items-center xl:gap-md whitespace-nowrap",
      )}
    >
      <span className="inline-flex items-center gap-xs">
        <IconCutProfileCircle />
        {firstValue}
      </span>
      <span className="inline-flex items-center gap-xs">
        {secondValueParsed[0] === secondValueParsed[1] ? (
          <IconCutProfile />
        ) : secondValueParsed[0] > secondValueParsed[1] ? (
          <IconCutProfileHorizontal />
        ) : (
          <IconCurProfileVertical />
        )}
        {secondValue}
      </span>
    </span>
  );
}
