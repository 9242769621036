import clsx from "clsx";
import { useContext } from "react";
import { Tooltip } from "@nextui-org/react";
import { useTranslations } from "next-intl";

import {
  TechnicalSpecificationKey,
  TechnicalSpecificationLabelKey,
} from "../../../app/api/models/enums";
import { Product as ProductInterface } from "../../../app/api/models/types";
import { TECHNICAL_SPECIFICATION_LABELS_SHORT } from "../../../consts/product/technical-speficiation";
import ProductCutsDetailed from "../cuts-detailed";
import ProductCutsRange from "../cuts-range";

import { ProductContext } from "@/contexts/product";
import { IconCuts, IconSize } from "@/icons/svgr";
import { bodyText } from "@/components/primitives";

interface Props {
  className?: string;
  product: ProductInterface;
  hideAllSpecificationButton?: boolean;
  isVisible?: boolean;
}

export default function ProductTooltipSpecification({
  className,
  product,
  hideAllSpecificationButton = false,
  isVisible = true,
}: Props): JSX.Element {
  const { handleCuttingRange } = useContext(ProductContext);
  const t = useTranslations("product");

  const technicalData = product[TechnicalSpecificationKey.technical_data];
  const cuttingRanges = Object.entries(
    product[TechnicalSpecificationKey.cutting_range],
  )
    .filter(([_label, value]) => value)
    // @ts-ignore
    .map(([label]) => t(TECHNICAL_SPECIFICATION_LABELS_SHORT[label]))
    .join(" / ");
  const cuttingRangesArray = Object.entries(
    product[TechnicalSpecificationKey.cutting_range],
  ).filter(([_label, value]) => value);

  return (
    <div
      className={clsx(
        className,
        "flex flex-wrap lg:flex-nowrap py-md overflow-hidden transition-opacityt",
        isVisible ? "opacity-100" : "opacity-0",
        hideAllSpecificationButton
          ? "w-full gap-sm lg:gap-md"
          : "gap-md lg:gap-xl justify-between lg:justify-center xl:justify-star",
      )}
    >
      <div className={clsx("group flex items-center gap-sm overflow-hidden")}>
        <IconSize className="shrink-0" />
        <Tooltip
          closeDelay={100}
          content={
            <div className="text-left max-w-[480px]">
              <h4
                className={clsx(
                  bodyText({ size: "sm" }),
                  "border-b border-b-neutral-200 px-sm py-xs",
                )}
              >
                {t("technical-data")}
              </h4>
              <div className={clsx("grid grid-cols-3")}>
                <div
                  className={clsx(
                    bodyText({ size: "xs" }),
                    "group col-span-3 md:col-span-1 px-[12px] py-[10px]",
                  )}
                >
                  <strong>
                    {t(
                      TECHNICAL_SPECIFICATION_LABELS_SHORT[
                        TechnicalSpecificationLabelKey.technical_data_item_1
                      ],
                    )}
                  </strong>
                  <br />
                  {
                    technicalData[
                      TechnicalSpecificationLabelKey.technical_data_item_1
                    ]
                  }
                </div>
                <div
                  className={clsx(
                    bodyText({ size: "xs" }),
                    "group col-span-3 md:col-span-1 px-[12px] py-[10px]",
                  )}
                >
                  <strong>
                    {t(
                      TECHNICAL_SPECIFICATION_LABELS_SHORT[
                        TechnicalSpecificationLabelKey.technical_data_item_3
                      ],
                    )}
                  </strong>
                  <br />
                  {
                    technicalData[
                      TechnicalSpecificationLabelKey.technical_data_item_3
                    ]
                  }
                </div>
                <div
                  className={clsx(
                    bodyText({ size: "xs" }),
                    "group col-span-3 md:col-span-1 px-[12px] py-[10px]",
                  )}
                >
                  <strong>
                    {t(
                      TECHNICAL_SPECIFICATION_LABELS_SHORT[
                        TechnicalSpecificationLabelKey.technical_data_item_9
                      ],
                    )}
                  </strong>
                  <br />
                  {
                    technicalData[
                      TechnicalSpecificationLabelKey.technical_data_item_9
                    ]
                  }
                </div>
                <div
                  className={clsx(
                    bodyText({ size: "xs" }),
                    "group col-span-3 md:col-span-1 px-[12px] py-[10px]",
                  )}
                >
                  <strong>
                    {t(
                      TECHNICAL_SPECIFICATION_LABELS_SHORT[
                        TechnicalSpecificationLabelKey.technical_data_item_2
                      ],
                    )}
                  </strong>
                  <br />
                  {
                    technicalData[
                      TechnicalSpecificationLabelKey.technical_data_item_2
                    ]
                  }
                </div>
                <div
                  className={clsx(
                    bodyText({ size: "xs" }),
                    "group col-span-3 md:col-span-1 px-[12px] py-[10px]",
                  )}
                >
                  <strong>
                    {t(
                      TECHNICAL_SPECIFICATION_LABELS_SHORT[
                        TechnicalSpecificationLabelKey.technical_data_item_14
                      ],
                    )}
                  </strong>
                  <br />
                  {
                    technicalData[
                      TechnicalSpecificationLabelKey.technical_data_item_14
                    ]
                  }
                </div>
                <div
                  className={clsx(
                    bodyText({ size: "xs" }),
                    "group col-span-3 md:col-span-1 px-[12px] py-[10px]",
                  )}
                >
                  <strong>
                    {t(
                      TECHNICAL_SPECIFICATION_LABELS_SHORT[
                        TechnicalSpecificationLabelKey.technical_data_item_15
                      ],
                    )}
                  </strong>
                  <br />
                  {
                    technicalData[
                      TechnicalSpecificationLabelKey.technical_data_item_15
                    ]
                  }
                </div>
              </div>
            </div>
          }
          delay={300}
          placement="bottom"
          size="lg"
        >
          <button
            className={clsx(
              bodyText({ size: "xs", underline: true }),
              "bg-none p-0 text-left whitespace-nowrap text-ellipsis overflow-hidden",
            )}
          >
            {technicalData.technical_data_item_1}
          </button>
        </Tooltip>
      </div>
      <div
        className={clsx(
          "group flex items-center gap-sm overflow-hidden",
          hideAllSpecificationButton ? "grid-span-2" : "grid-span-1",
        )}
      >
        <IconCuts className="shrink-0" />
        <Tooltip
          closeDelay={100}
          content={
            <div className="text-left">
              <div className="flex gap-md">
                <div className="group">
                  <h4
                    className={clsx(
                      bodyText({ size: "sm" }),
                      "border-b border-b-neutral-200 px-sm py-xs",
                    )}
                  >
                    {t("cutting-range")}
                  </h4>
                  <table>
                    <tbody>
                      {cuttingRangesArray.map((cuttingRangeArray, index) => {
                        return (
                          <tr
                            key={cuttingRangeArray[0]}
                            className={clsx(
                              bodyText({ size: "xs" }),
                              "flex border-t first:border-t-0 border-t-gray",
                            )}
                            onBlur={handleCuttingRange(null)}
                            onFocus={handleCuttingRange(cuttingRangeArray[0])}
                            onMouseOut={handleCuttingRange(null)}
                            onMouseOver={handleCuttingRange(
                              cuttingRangeArray[0],
                            )}
                          >
                            <th className="flex-1 px-[12px] py-[10px]">
                              {t(
                                /* @ts-ignore */
                                TECHNICAL_SPECIFICATION_LABELS_SHORT[
                                  cuttingRangeArray[0]
                                ],
                              )}
                            </th>
                            <td className="px-[12px] py-[10px]">
                              <ProductCutsRange
                                key={`item-${index}`}
                                values={
                                  cuttingRangeArray[1] as unknown as string[]
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="group flex justify-end">
                  <ProductCutsDetailed product={product} />
                </div>
              </div>
            </div>
          }
          delay={300}
          placement="bottom"
          size="lg"
        >
          <button
            className={clsx(
              bodyText({ size: "xs", underline: true }),
              "bg-none p-0 text-left whitespace-nowrap text-ellipsis overflow-hidden",
            )}
          >
            {cuttingRanges}
          </button>
        </Tooltip>
      </div>
      {!hideAllSpecificationButton && (
        <div
          className={clsx(
            "group w-full lg:w-auto flex justify-center lg:justify-start whitespace-nowrap text-ellipsis overflow-hidden",
          )}
        >
          <a
            className={bodyText({ size: "sm", underline: true })}
            color="foreground"
            href="#specification"
          >
            {t("all-technical-data")}
          </a>
        </div>
      )}
    </div>
  );
}
